// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-bt-twitch-commands-js": () => import("/opt/build/repo/src/templates/btTwitchCommands.js" /* webpackChunkName: "component---src-templates-bt-twitch-commands-js" */),
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-nicelist-js": () => import("/opt/build/repo/src/templates/nicelist.js" /* webpackChunkName: "component---src-templates-nicelist-js" */),
  "component---src-templates-past-meetups-page-js": () => import("/opt/build/repo/src/templates/past-meetups-page.js" /* webpackChunkName: "component---src-templates-past-meetups-page-js" */),
  "component---src-templates-values-js": () => import("/opt/build/repo/src/templates/values.js" /* webpackChunkName: "component---src-templates-values-js" */),
  "component---src-templates-redirect-js": () => import("/opt/build/repo/src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

